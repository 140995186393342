<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap30Concurrency"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 30: Concurrency" image-name="concurrency.jpg" image-alt="Concurrency"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-you-will-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What you will learn in this chapter ? <a href="#what-you-will-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is concurrency and parallelism.</p></li>
<li><p>What is a data race and a deadlock and how to avoid them</p></li>
<li><p>What is a goroutine, a channel, a select statement, a wait group, a mutex.</p></li>
<li><p>How to write a concurrent program with those tools.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Mutex</p></li>
<li><p>Channel</p></li>
<li><p>Concurrency</p></li>
<li><p>Parallelism</p></li>
<li><p>Wait group</p></li>
<li><p>Deadlock</p></li>
<li><p>Data Race</p></li>
<li><p>Select statement</p></li>
</ul>
<div id="preliminary-definitions" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Preliminary definitions <a href="#preliminary-definitions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="process" class="anchor"></div>
<h2 data-number="3.1"><span class="header-section-number">3.1</span> Process <a href="#process"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A process is an instance of a program that is currently executed by a computer.</p>
<p>If you want to see the list of processes that run on your machine, you can open a terminal and run the following command (on UNIX systems) :</p>
<pre v-highlightjs><code class="go" v-pre >$ ps xau</code></pre>
<p>You will see the complete list of processes that are running on your machine. This command will output the following informations :</p>
<ul>
<li><p><strong>USER</strong> : the user that launched the process</p></li>
<li><p><strong>PID</strong> : the id of the process (each process on a coputer has an unique id, therefore it makes esy to identicate one process)</p></li>
<li><p><strong>%CPU</strong> : Will display the percetage of the CPU usage of the process</p></li>
<li><p><strong>%MEM</strong> : the percentage of the memory used</p></li>
<li><p><strong>COMMAND</strong> : the command of the process including the arguments</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/ps-xau-example.png')" alt="Example output of ps"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Example output of ps</figcaption>
</figure>
<ul>
<li><p>Here we have <strong>two</strong> processes</p></li>
<li><p>They were both started with the same command <span v-highlightjs><code class="go" v-pre style="display: inline">./programFoo</code></span></p></li>
<li><p>They have two different process ids : 65865 and 65689.</p></li>
<li><p>Both were launched today at 11:37 and 11:38</p></li>
</ul>
<p>On <strong>Windows</strong> you can type :</p>
<pre v-highlightjs><code class="go" v-pre >$ tasklist</code></pre>
<div id="threads-of-execution" class="anchor"></div>
<h2 data-number="3.2"><span class="header-section-number">3.2</span> Threads of execution <a href="#threads-of-execution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A thread represents the sequential execution of a set of instructions. A single process can create <strong>multiple</strong> threads.</p>
<p>For each process we have at least one thread of execution.</p>
<p>By creating more than one thread we create multiple execution streams that may share some data.</p>
<div id="what-is-concurrency" class="anchor"></div>
<h2 data-number="3.3"><span class="header-section-number">3.3</span> What is concurrency ? <a href="#what-is-concurrency"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>“Concurrency is the <strong>ability</strong> of different parts or units of a program, algorithm, or problem to be executed <strong>out-of-order or in partial order</strong>, without affecting the final outcome”<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a> Concurrency refers to an <strong>ability</strong>. Go supports concurrency. We can write a Go program that will run a set of tasks <strong>concurrently</strong>.</p>
<div id="what-is-parallelism" class="anchor"></div>
<h2 data-number="3.4"><span class="header-section-number">3.4</span> What is parallelism ? <a href="#what-is-parallelism"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The previous notion of concurrency can be mixed up with parallelism. “Concurrency is not parallelism” (Rob Pike) <a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a>. Parallelism refer to tasks that are executed simultaneously (at the same time). A concurrent program <strong>might</strong> run tasks in parallel.</p>
<div id="concurrency-pitfalls" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Concurrency pitfalls <a href="#concurrency-pitfalls"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="data-race" class="anchor"></div>
<h2 data-number="4.1"><span class="header-section-number">4.1</span> Data race <a href="#data-race"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="definition" class="anchor"></div>
<h3 data-number="4.1.1"><span class="header-section-number">4.1.1</span> Definition <a href="#definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>A data race may occur if two (or more) threads of execution access a shared variable</p>
<ul>
<li><p>AND when one (or more) thread(s) want to update the variable</p></li>
<li><p>AND there is no “explicit mechanism to prevent the accesses from being simultaneous” in the threads <b-link class="citation" data-cites="savage1997eraser" href="#savage1997eraser" >[@savage1997eraser]</b-link>.</p></li>
</ul>
<div id="example" class="anchor"></div>
<h3 data-number="4.1.2"><span class="header-section-number">4.1.2</span> Example <a href="#example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Imagine that you have developed an e-commerce website that handles request concurrently.</p>
<p>You have two clients at the same time that are interested by the same product : a computer screen. The current stock of the product is stored in a database.</p>
<table>
<caption>Data race example</caption>
<thead>
<tr class="header">
<th style="text-align: center;">time</th>
<th style="text-align: center;">John</th>
<th style="text-align: center;">Jeanne</th>
<th style="text-align: center;">Stock in Database</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">0</td>
<td style="text-align: center;">Load the product page</td>
<td style="text-align: center;">Load the product page</td>
<td style="text-align: center;">1</td>
</tr>
<tr class="even">
<td style="text-align: center;">1</td>
<td style="text-align: center;">Press the order button</td>
<td style="text-align: center;">Wait</td>
<td style="text-align: center;">1</td>
</tr>
<tr class="odd">
<td style="text-align: center;">2</td>
<td style="text-align: center;">Stock OK ? =&gt; Yes</td>
<td style="text-align: center;">Press the order button</td>
<td style="text-align: center;">1</td>
</tr>
<tr class="even">
<td style="text-align: center;">3</td>
<td style="text-align: center;">Update the stock to 0</td>
<td style="text-align: center;">Stock OK ? =&gt; Yes</td>
<td style="text-align: center;">1</td>
</tr>
<tr class="odd">
<td style="text-align: center;">4</td>
<td style="text-align: center;"></td>
<td style="text-align: center;">Update the stock to 0</td>
<td style="text-align: center;">0</td>
</tr>
<tr class="even">
<td style="text-align: center;">5</td>
<td style="text-align: center;"></td>
<td style="text-align: center;">Display confirmation page</td>
<td style="text-align: center;">0</td>
</tr>
<tr class="odd">
<td style="text-align: center;">6</td>
<td style="text-align: center;">...</td>
<td style="text-align: center;">Display confirmation page</td>
<td style="text-align: center;">0</td>
</tr>
</tbody>
</table>
<p>Let’s take our example step by step :</p>
<ul>
<li><p>At time 0 John and Jeanne load the same product page.</p></li>
<li><p>At time 1 John press the order button</p></li>
<li><p>When John clicks on the order button our script will check the stock in database.</p></li>
<li><p>At that time it’s OK we have 1 in stock (see the last column) We launch the update process in database that will take two units of time.</p></li>
<li><p>At time 3 and 4 the product is being updated. At the the beginning of time 5 the product stock is therefore 0 in database...</p></li>
<li><p>But Jeanne has also pressed the order button . The update of the stock is also launched at time 4.</p></li>
</ul>
<p>We have sold something that we do not have in stock. This is a data race. It happened because we have two threads (John and Jeanne) that want to access in write to the same <strong>shared variable</strong>, and we had <strong>no mechanism to prevent the simultaneous access</strong>.…</p>
<div id="data-race-detector" class="anchor"></div>
<h3 data-number="4.1.3"><span class="header-section-number">4.1.3</span> Data race detector <a href="#data-race-detector"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>When you build and test your program you can use the Go race detector. It will detect potential errors :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -race -o myProgramName main.go</code></pre>
<div id="deadlock" class="anchor"></div>
<h2 data-number="4.2"><span class="header-section-number">4.2</span> Deadlock <a href="#deadlock"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="definition-1" class="anchor"></div>
<h3 data-number="4.2.1"><span class="header-section-number">4.2.1</span> Definition <a href="#definition-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>A deadlock occurs when two components of a program are waiting for each others. In such a case the whole system is blocked. No progress can be made in execution of the whole program.</p>
<div id="example-five-dining-philosophers" class="anchor"></div>
<h3 data-number="4.2.2"><span class="header-section-number">4.2.2</span> Example : five dining philosophers <a href="#example-five-dining-philosophers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>This problem was originally created by Edsger W. Dijkstra.</p>
<p>The problem is stated as follow :</p>
<ul>
<li><p>We have a round table</p></li>
<li><p>5 philosophers are seated.</p></li>
<li><p>Each philosopher has a spaghetti plate in front of them</p></li>
<li><p>Between each plate there is a fork placed</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/five_dining_philo.png')" alt="The five dining philosophers"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The five dining philosophers</figcaption>
</figure>
<p>The rules of the diner are the following :</p>
<ol type="1">
<li><p>To eat, a guest must have two forks</p></li>
<li><p>A guest can only use the left and the right fork</p></li>
</ol>
<p>We need to design a program for each philosopher.</p>
<p>A solution can be the following :</p>
<ul>
<li><p>Repeat until the diner is finished:</p>
<ul>
<li><p>When the right fork is available take it</p></li>
<li><p>When the left fork is available take it</p></li>
<li><p>When you have both forks start to eat 100 gr of spaghetti</p></li>
<li><p>Release the two forks</p></li>
</ul></li>
</ul>
<p>Let’s load the program into the brain of each philosopher and start the diner. Each philosopher will be a <strong>thread</strong> in our main “diner” program.</p>
<p>When you start the diner the following actions will be trigerred :</p>
<ul>
<li><p>The philosopher 1 will take the fork I (since it’s available)</p></li>
<li><p>The philosopher 2 will take the fork II</p></li>
<li><p>The philosopher 3 will take the fork III</p></li>
<li><p>The philosopher 4 will take the fork IV</p></li>
<li><p>The philosopher 5 will take the fork V</p></li>
</ul>
<p>In this situation the program of each philosopher as executed the first instruction of our program :</p>
<pre v-highlightjs><code class="go" v-pre >When the right fork is available take it</code></pre>
<p>Then the second instruction :</p>
<pre v-highlightjs><code class="go" v-pre >When the left fork is available take it</code></pre>
<p>Cannot be executed because the left fork is not available. The program is blocked. It’s a Deadlock !</p>
<div id="goroutines" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Goroutines <a href="#goroutines"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The main tool to create concurrent systems in Go is the goroutine.</p>
<div id="the-main-goroutine" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> The main goroutine <a href="#the-main-goroutine"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In each program there is a goroutine this is the <strong>main goroutine</strong>. In order to demonstrate it let’s build a simple program and let’s make it panic. We will see in the stacktrace (the error messages) that there is a goroutine behind it :</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/main-goroutine/main.go 
package main

func main(){
    panic(&quot;show me the goroutine&quot;)
}</code></pre>
<p>Let’s build the program (go build) and launch the executable. We have the following stacktrace :</p>
<pre v-highlightjs><code class="go" v-pre >panic: show me the goroutine
goroutine 1 [running]:
main.main()</code></pre>
<p>We have a single goroutine. The main goroutine that has the index 1.</p>
<div id="the-official-definition" class="anchor"></div>
<h2 data-number="5.2"><span class="header-section-number">5.2</span> The official definition <a href="#the-official-definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A goroutine is a function that executes <strong>independently</strong> from the rest of the program. The basic element of a goroutine is a function. Every function can become goroutines. Launching a goroutine is as simple as launching a function except that you just add the “go” word just before the function call.</p>
<div id="example-1" class="anchor"></div>
<h2 data-number="5.3"><span class="header-section-number">5.3</span> Example <a href="#example-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>We define a function <span v-highlightjs><code class="go" v-pre style="display: inline">printNumber</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >func printNumber(){     
    i :=0   
    for {       
        time.Sleep(1 * time.Second)         
        i++         
        fmt.Println(i)  
    } 
}</code></pre>
<ul>
<li><p>It begins with the initialization of a variable <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> at the value 0.</p></li>
<li><p>Then it starts an infinite loop (with the <strong>for</strong> instruction).</p>
<ul>
<li><p>In this infinite loop we make the program pause for one second with <span v-highlightjs><code class="go" v-pre style="display: inline">time.Sleep(1 * time.Second)</code></span><strong>.</strong></p></li>
<li><p>After that we increment <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> (<span v-highlightjs><code class="go" v-pre style="display: inline">i++</code></span>)</p></li>
<li><p>and then we print <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span></p></li>
</ul></li>
</ul>
<p>After that we declare our main function (the main goroutine) :</p>
<pre v-highlightjs><code class="go" v-pre >func main(){    
    fmt.Println(&quot;launch goroutine&quot;)     
    go printNumber()    
    fmt.Println(&quot;launch goroutine&quot;)     
    go printNumber()    
    time.Sleep(1 * time.Minute) 
}</code></pre>
<ul>
<li><p>In this program we launch 2 goroutines sequentially.</p></li>
<li><p>At the end we pause the program 1 minute.</p></li>
</ul>
<p>Why this last pause ? When you do not add the pause the program will just stop right after it’s execution because the thread of execution has reached the end of the main function. If we want to see our goroutine execute we have to wait them to be launched. <strong>Launching a goroutine does not block the main thread.</strong></p>
<p>Here is the complete program :</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/goroutine-example/main.go 
package main

import (    
    &quot;fmt&quot;   
    &quot;time&quot;  
)

func main(){    
    fmt.Println(&quot;launch first goroutine&quot;)   
    go printNumber()    
    fmt.Println(&quot;launch second goroutine&quot;)  
    go printNumber()    
    time.Sleep(1 * time.Minute) 
}

func printNumber(){     
    i :=0   
    for {       
        time.Sleep(1 * time.Second)         
        i++         
        fmt.Println(i)  
    } 
}</code></pre>
<p>The execution of this program will output the following :</p>
<pre v-highlightjs><code class="go" v-pre >launch first goroutine 
launch second goroutine 
1 
1 
2 
2 
3 
3 
4 
4</code></pre>
<div id="channels" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Channels <a href="#channels"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="a-channel-is-a-typed-communication-pipeline." class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> A channel is a typed communication pipeline. <a href="#a-channel-is-a-typed-communication-pipeline."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Goroutines can communicate with each others through channels. A channel can be seen as a pipeline of data between two goroutines. This pipeline can only support <strong>a specific type.</strong></p>
<figure>
<b-img :src="require('@/assets/images/channel_communication_between_goroutines.png')" alt="Channels allow communication between goroutines"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Channels allow communication between goroutines</figcaption>
</figure>
<div id="channel-types" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> Channel types <a href="#channel-types"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Channels can be :</p>
<ul>
<li><p>Send Only</p></li>
<li><p>Receive only</p></li>
<li><p>Bidirectional (it can send or receive)</p></li>
</ul>
<div id="syntax" class="anchor"></div>
<h4 data-number="6.2.0.1"><span class="header-section-number">6.2.0.1</span> Syntax <a href="#syntax"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ol type="1">
<li><p>A channel that can can be used to <strong>send</strong> values of type T is denoted : <span v-highlightjs><code class="go" v-pre style="display: inline">chan&lt;- T</code></span></p></li>
<li><p>A channel that can can be used to <strong>receive</strong> values of type T is denoted : <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;-chan T</code></span></p></li>
<li><p>A channel that can can be used to send and <strong>receive</strong> values of type T is denoted : <span v-highlightjs><code class="go" v-pre style="display: inline">chan T</code></span></p></li>
</ol>
<figure>
<b-img :src="require('@/assets/images/channel_direction.png')" alt="Channel direction"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Channel direction</figcaption>
</figure>
<p>The zero value of a channel type is <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span></p>
<div id="channel-initialization-capacity" class="anchor"></div>
<h2 data-number="6.3"><span class="header-section-number">6.3</span> Channel initialization, capacity <a href="#channel-initialization-capacity"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Channels are initialized with the <span v-highlightjs><code class="go" v-pre style="display: inline">make</code></span> built-in :</p>
<p>In order to <strong>initialize</strong> a bidirectional <strong>unbuffered</strong> channel of <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> you can use the following code :</p>
<pre v-highlightjs><code class="go" v-pre >ch1 := make(chan int)</code></pre>
<p>In order to <strong>initialize</strong> a bidirectional <strong>buffered</strong> channel of <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span> you can use the following code :</p>
<pre v-highlightjs><code class="go" v-pre >ch2 := make(chan string, 3)</code></pre>
<p>3 is the <strong>capacity</strong> of the channel. This is the space allocated by Go to store the values sent to the channel.</p>
<div id="unbuffered-channel" class="anchor"></div>
<h3 data-number="6.3.1"><span class="header-section-number">6.3.1</span> Unbuffered channel <a href="#unbuffered-channel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>A channel is <strong>un</strong>buffered when you do not specify it’s capacity when you create it. A channel with a size of zero is also <strong>un</strong>buffered.</p>
<p>To create an unbuffered channel you can use the following code source :</p>
<pre v-highlightjs><code class="go" v-pre >ch3 := make(chan float)</code></pre>
<p>Or by specifying explicitely a size of 0 (which is equivalent to the previous notation) :</p>
<pre v-highlightjs><code class="go" v-pre >ch4 := make(chan float, 0)</code></pre>
<div id="buffered-channels" class="anchor"></div>
<h3 data-number="6.3.2"><span class="header-section-number">6.3.2</span> Buffered channels <a href="#buffered-channels"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>A buffered channel is a channel where you specify the buffer size when you create it.</p>
<pre v-highlightjs><code class="go" v-pre >ch6 := make(chan float, 16)</code></pre>
<p>Here we have created a buffered channel with capacity of 16.</p>
<figure>
<b-img :src="require('@/assets/images/channel_unbuffered_buffered.png')" alt="Buffered and Unbuffered channels"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Buffered and Unbuffered channels</figcaption>
</figure>
<div id="send-data-on-a-channel" class="anchor"></div>
<h2 data-number="6.4"><span class="header-section-number">6.4</span> Send data on a channel <a href="#send-data-on-a-channel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s say that we have a channel called <span v-highlightjs><code class="go" v-pre style="display: inline">ch5</code></span>. To send an element to this channel we will use the arrow syntax : <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;-</code></span><strong>.</strong></p>
<p>Those two characters convey the idea of data that flows <strong>from the right to the left.</strong></p>
<pre v-highlightjs><code class="go" v-pre >package main

func main() {
    ch5 := make(chan int, 2)
    ch5 &lt;- 42
}</code></pre>
<p>In the previous snippet :</p>
<ul>
<li><p>We initialize a bidirectional buffered channel of integers (<span v-highlightjs><code class="go" v-pre style="display: inline">ch5</code></span>)</p></li>
<li><p>Then we <strong>send</strong> the number 42 into the channel with the syntax :</p>
<ul>
<li>channel &lt;- expression</li>
</ul></li>
</ul>
<div id="the-specificity-of-a-send-statement" class="anchor"></div>
<h3 data-number="6.4.1"><span class="header-section-number">6.4.1</span> The specificity of a send statement <a href="#the-specificity-of-a-send-statement"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Send statements have some specific rules :</p>
<ol type="1">
<li><p>The channel and the expression are evaluated before communication</p></li>
<li><p>You can send on a channel if it’s open. If you send on a closed channel your program will panic !</p></li>
<li><p>If you send on a nil channel it blocks your program forever</p></li>
</ol>
<div id="closing-a-channel" class="anchor"></div>
<h2 data-number="6.5"><span class="header-section-number">6.5</span> Closing a channel <a href="#closing-a-channel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A channel can be closed with the built-in <span v-highlightjs><code class="go" v-pre style="display: inline">close</code></span>. Closing a channel indicate that “no more values will be sent on the channel”<a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a>.</p>
<ul>
<li><p>You cannot close a receive only channel.</p></li>
<li><p>You cannot send data on a closed channel.</p></li>
<li><p>You cannot close a channel already closed.</p></li>
<li><p>You can receive data on a closed channel (see next section)</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >package main

import &quot;log&quot;

func main() {
    var received int
    ch5 := make(chan int, 2)
    ch5 &lt;- 42
    close(ch5)
}</code></pre>
<p>Here we send the value 42 into the channel, then we close it with the close built-in : <span v-highlightjs><code class="go" v-pre style="display: inline">close(ch5)</code></span>.</p>
<div id="receive-data-from-a-channel" class="anchor"></div>
<h2 data-number="6.6"><span class="header-section-number">6.6</span> Receive data from a channel <a href="#receive-data-from-a-channel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In order to receive something from a channel you can use two types of syntax</p>
<div id="the-basic-receive-operation" class="anchor"></div>
<h3 data-number="6.6.1"><span class="header-section-number">6.6.1</span> The basic receive operation <a href="#the-basic-receive-operation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >package main

import &quot;log&quot;

func main() {
    var received int
    ch5 := make(chan int, 2)
    ch5 &lt;- 42
    ch5 &lt;- 41
    received = &lt;-ch5
    log.Println(received)
}</code></pre>
<p>In this code snippet:</p>
<ul>
<li><p>We create a buffered channel of integers : <span v-highlightjs><code class="go" v-pre style="display: inline">ch5</code></span></p></li>
<li><p>We send the values 42 and 41 into the channel</p></li>
<li><p>We set the value of <span v-highlightjs><code class="go" v-pre style="display: inline">received</code></span> to <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;-ch5</code></span></p></li>
<li><p>We print the value of <span v-highlightjs><code class="go" v-pre style="display: inline">received</code></span></p></li>
</ul>
<p>The previous script will output : <strong>42.</strong></p>
<div id="multi-valued-receive-operation" class="anchor"></div>
<h3 data-number="6.6.2"><span class="header-section-number">6.6.2</span> Multi-valued receive operation <a href="#multi-valued-receive-operation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>This syntax is used in order to be sure that our channel is not closed or empty. (source : <b-link class="citation" data-cites="go-specs" href="#go-specs" >[@go-specs]</b-link>)</p>
<pre v-highlightjs><code class="go" v-pre >x, ok = &lt;-ch 
if !ok {
    log.Println(&quot;channel is empty or closed&quot;)
}</code></pre>
<p>In this example we have the variable <strong>x</strong> that will hold the value of what is sent in the channel but we add an <strong>ok</strong> variable that will help us determine if the channel is empty or closed.</p>
<p>The value of <strong>ok</strong> is a boolean and will be equal to :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span> : is everything is ok, we received a value. (nominal case)</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span> : if the channel is <strong>empty</strong> or <strong>closed</strong>.</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >package main

import &quot;fmt&quot;

func main() {
    ch5 := make(chan int, 2)
    ch5 &lt;- 42
    close(ch5)
    received, ok := &lt;-ch5
    fmt.Println(received, ok)
}</code></pre>
<p>This program will output :</p>
<pre v-highlightjs><code class="go" v-pre >42 true</code></pre>
<p>The channel is closed so ok is true and we still received a value from the channel 42 (was sent before closing)</p>
<div id="syntax-summary" class="anchor"></div>
<h2 data-number="6.7"><span class="header-section-number">6.7</span> Syntax summary <a href="#syntax-summary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/channel_summary.png')" alt="Init, receive, send and close"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Init, receive, send and close</figcaption>
</figure>
<div id="how-to-remember-the-syntax-easily" class="anchor"></div>
<h2 data-number="6.8"><span class="header-section-number">6.8</span> How to remember the syntax easily ? <a href="#how-to-remember-the-syntax-easily"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>When the arrow <strong>points to the channel</strong> we <strong>send</strong> data <strong>into</strong> the channel</p></li>
<li><p>Otherwise we receive data from the channel</p></li>
</ul>
<div id="channel-capacity-and-send-operations" class="anchor"></div>
<h2 data-number="6.9"><span class="header-section-number">6.9</span> Channel capacity and send operations <a href="#channel-capacity-and-send-operations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="unbuffered-channel-capacity-0" class="anchor"></div>
<h3 data-number="6.9.1"><span class="header-section-number">6.9.1</span> Unbuffered channel (capacity = 0) <a href="#unbuffered-channel-capacity-0"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li>When you send data into an unbuffered channel your current goroutine <strong>will be blocked until the data is received</strong> by another goroutine.</li>
</ul>
<p>There is no buffer allocated to store the message sent, as a consequence the sender blocks until another goroutine receive the message.</p>
<div id="example-2" class="anchor"></div>
<h4 data-number="6.9.1.1"><span class="header-section-number">6.9.1.1</span> Example <a href="#example-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// concurrency/channel-capacity-send/main.go 
package main

import (
    &quot;log&quot;
    &quot;time&quot;
)

func main() {
    ch := make(chan int)
    go dummy(ch)
    log.Println(&quot;waiting for reception...&quot;)
    ch &lt;- 45
    log.Println(&quot;received&quot;)
}

func dummy(c chan int) {
    time.Sleep(3 * time.Second)
    &lt;-c
}</code></pre>
<ul>
<li><p>We create an unbuffered channel of integers <span v-highlightjs><code class="go" v-pre style="display: inline">ch</code></span></p></li>
<li><p>A second goroutine is launched with <span v-highlightjs><code class="go" v-pre style="display: inline">go dummy(ch)</code></span></p></li>
<li><p>The goroutine will wait 3 seconds then receive data on the input channel.</p>
<ul>
<li>The data is received is discarded (we do not store it into a variable)</li>
</ul></li>
<li><p>In the main goroutine we send the value 45 into the channel.</p></li>
</ul>
<p>This program outputs</p>
<pre v-highlightjs><code class="go" v-pre >2021/02/15 21:53:27 waiting for reception...
2021/02/15 21:53:30 received</code></pre>
<ul>
<li><p>The send operation blocks the main goroutine</p></li>
<li><p>It is unblocked when the dummy goroutine receive the value sent</p></li>
</ul>
<div id="buffered-channel-capacity-0" class="anchor"></div>
<h3 data-number="6.9.2"><span class="header-section-number">6.9.2</span> Buffered channel (capacity &gt; 0) <a href="#buffered-channel-capacity-0"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li>When you send data into an buffered channel your current goroutine <strong>will be blocked until the data is copied to the buffer</strong>.</li>
</ul>
<p>A buffered channel has a buffer allocated. Therefore the sender needs only to wait until the data has been efectively copied to the internal buffer.</p>
<p>Let’s take the previous program and add a capacity to the channel :</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/channel-capacity-send-buffered/main.go 
package main

import (
    &quot;log&quot;
    &quot;time&quot;
)

func main() {
    ch := make(chan int, 1)
    go dummy(ch)
    log.Println(&quot;waiting for reception...&quot;)
    ch &lt;- 45
    log.Println(&quot;received&quot;)
}

func dummy(c chan int) {
    time.Sleep(3 * time.Second)
    &lt;-c
}</code></pre>
<p>The program output is :</p>
<pre v-highlightjs><code class="go" v-pre >2021/02/15 21:56:05 waiting for reception...
2021/02/15 21:56:05 received</code></pre>
<p>You can note that the send operation is not blocking. 45 is written to the channel buffen and then the next line of code is executed.</p>
<div id="channel-capacity-and-receive-operations" class="anchor"></div>
<h2 data-number="6.10"><span class="header-section-number">6.10</span> Channel capacity and receive operations <a href="#channel-capacity-and-receive-operations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>For buffered and unbuffered channels a receive operation blocks the goroutine until a message is received.</p>
<div id="len-cap" class="anchor"></div>
<h2 data-number="6.11"><span class="header-section-number">6.11</span> Len / cap <a href="#len-cap"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>The builtin <span v-highlightjs><code class="go" v-pre style="display: inline">len</code></span> will return the number of elements queued into the channel buffer</p></li>
<li><p>The builtin <span v-highlightjs><code class="go" v-pre style="display: inline">cap</code></span> will return the buffer capacity</p></li>
</ul>
<div id="summary" class="anchor"></div>
<h2 data-number="6.12"><span class="header-section-number">6.12</span> Summary <a href="#summary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<table style="width:96%;">
<caption>Buffered vs unbuffered channel</caption>
<colgroup>
<col style="width: 31%" />
<col style="width: 31%" />
<col style="width: 31%" />
</colgroup>
<thead>
<tr class="header">
<th style="text-align: left;"></th>
<th style="text-align: left;"><strong>Unbuffered channel</strong></th>
<th style="text-align: left;"><strong>Buffered channel</strong></th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: left;">Init.</td>
<td style="text-align: left;"><pre v-highlightjs><code class="go" v-pre >make(chan float)</code></pre></td>
<td style="text-align: left;">make(chan float, 16)</td>
</tr>
<tr class="even">
<td style="text-align: left;">Send is blocking..</td>
<td style="text-align: left;">until the recipient has received the value</td>
<td style="text-align: left;">until the data is copied to the buffer</td>
</tr>
<tr class="odd">
<td style="text-align: left;">Receive is blocking...</td>
<td style="text-align: left;"></td>
<td style="text-align: left;"></td>
</tr>
</tbody>
</table>
<div id="use-case-of-channels" class="anchor"></div>
<h2 data-number="6.13"><span class="header-section-number">6.13</span> Use case of channels <a href="#use-case-of-channels"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li>Unbuffered channels are used to <strong>synchronize two goroutines</strong></li>
</ul>
<p>Here is an example<a href="#fn4" class="footnote-ref" id="fnref4" role="doc-noteref"><sup>4</sup></a> :</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/channel-usecase/main.go
package main

import (
    &quot;time&quot;
)

func main() {
    syncCh := make(chan bool)
    // launch a second goroutine
    go func() {
        longTask2()
        // finished
        syncCh &lt;- true
    }()
    longTask()
    // blocks until the second goroutine has finished
    &lt;-syncCh
}

func longTask2() {
    time.Sleep(1 * time.Second)
}

func longTask() {
    time.Sleep(3 * time.Second)
}</code></pre>
<p>The unbuffered channel is used here to synchronize the main goroutine with the secong goroutine. The receive operation <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;-syncCh</code></span> is blocking until the other goroutine has finished. To signal that it has finished the second goroutine will send the value “true” in the channel.</p>
<ul>
<li><p>Buffered channels are used to limit <strong>limit throughput</strong> between goroutines</p>
<ul>
<li>We will see how it works in the application.</li>
</ul></li>
</ul>
<div id="how-to-cause-a-deadlock" class="anchor"></div>
<h2 data-number="6.14"><span class="header-section-number">6.14</span> How to cause a deadlock <a href="#how-to-cause-a-deadlock"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="with-a-buffered-channel" class="anchor"></div>
<h4 data-number="6.14.0.1"><span class="header-section-number">6.14.0.1</span> With a buffered channel <a href="#with-a-buffered-channel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// concurrency/deadlock/main.go
package main

import (
    &quot;log&quot;
)

func main() {
    ch := make(chan int, 1)
    go dummy(ch)
    log.Println(&quot;waiting for reception...&quot;)
    ch &lt;- 45
    ch &lt;- 58
    ch &lt;- 100
}

func dummy(c chan int) {
    smth := &lt;-c
    log.Println(&quot;has received something&quot;, smth)
}</code></pre>
<p>This code will cause a dedlock. Here is the execution result :</p>
<pre v-highlightjs><code class="go" v-pre >2021/02/16 11:19:57 waiting for reception...
2021/02/16 11:19:57 has received something 45
fatal error: all goroutines are asleep - deadlock!

goroutine 1 [chan send]:
main.main()
        /Users/maximilienandile/Documents/DEV/goBook/concurrency/sendBlocking/main.go:15 +0xea

Process finished with exit code 2</code></pre>
<ul>
<li><p>We have created an buffered channel with a capacity equal to 1</p></li>
<li><p>This channel is passed to a new goroutine that will <strong>receive</strong> data on the channel (the <span v-highlightjs><code class="go" v-pre style="display: inline">dummy</code></span> function).</p></li>
<li><p>We send three values on the channel : 45, 58 and 100.</p></li>
<li><p>The first value is received by the dummy goroutine.</p>
<ul>
<li>There is room on the buffer to store the second value</li>
</ul></li>
<li><p>When we send the third value on the channel the main goroutine will be blocked</p>
<ul>
<li>It is blocked until the third value is copied to the channel buffer</li>
</ul></li>
<li><p>The program will wait indefinitely.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/deadluck_unbuffered.png')" alt="An example deadlock with an buffered channel"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">An example deadlock with an buffered channel</figcaption>
</figure>
<div id="with-an-unbuffered-channel" class="anchor"></div>
<h4 data-number="6.14.0.2"><span class="header-section-number">6.14.0.2</span> With an unbuffered channel <a href="#with-an-unbuffered-channel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// concurrency/deadlock-unbuffered/main.go
package main

func main() {
    ch := make(chan int)
    ch &lt;- 5
}</code></pre>
<p>This simple program will cause a deadlock. The main goroutine is waiting indefinitely until a receipient receive the data sent.</p>
<div id="select-statement" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Select statement <a href="#select-statement"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Select statements are used to choose “which of a set of possible send or receive operations will proceed”<a href="#fn5" class="footnote-ref" id="fnref5" role="doc-noteref"><sup>5</sup></a>.</p>
<ul>
<li><p>A select statement is similar to a <strong>switch case</strong> statement but for communication operations.</p></li>
<li><p>In a select statement you have <strong>cases</strong> and an <strong>optional</strong> <strong>default</strong> case.</p></li>
<li><p>The first non-blocking case will be chosen</p></li>
<li><p>If <strong>2 or more</strong> cases are not blocking <strong>a single one is chosen</strong> via an “uniform pseudo-random” selection.</p></li>
<li><p>If all cases are blocking, then the default case is chosen</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/select_statement_syntax.png')" alt="Select Statement syntax"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Select Statement syntax</figcaption>
</figure>
<div id="without-default" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> Without Default <a href="#without-default"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// concurrency/select-without-default/main.go
package main

import &quot;log&quot;

func main() {

    ch1 := make(chan string, 1)
    ch2 := make(chan string, 1)
    ch1 &lt;- &quot;test&quot;

    select {
    case rec, ok := &lt;-ch1:
        if ok {
            log.Printf(&quot;received on ch1 : %s&quot;, rec)
        }
    case rec, ok := &lt;-ch2:
        if ok {
            log.Printf(&quot;received on ch2 : %s&quot;, rec)
        }
    }
    log.Println(&quot;end&quot;)
}</code></pre>
<ul>
<li><p>We have a select statement with 2 receive operations.</p></li>
<li><p>We are waiting for reception on <span v-highlightjs><code class="go" v-pre style="display: inline">ch1</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">ch2</code></span>.</p></li>
<li><p>The first non blocking case is chosen =&gt; here the first case <span v-highlightjs><code class="go" v-pre style="display: inline">case rec, ok := &lt;-ch1:</code></span></p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ch1</code></span> is the first channel to receive a message. Here is the output of this program :</p>
<pre v-highlightjs><code class="go" v-pre >2021/02/16 17:05:27 received on ch1 : test
2021/02/16 17:05:27 end</code></pre>
<div id="default" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Default <a href="#default"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// concurrency/select-with-default/main.go
package main

import &quot;log&quot;

func main() {

    ch1 := make(chan string, 1)
    ch2 := make(chan string, 1)
    select {
    case rec, ok := &lt;-ch1:
        if ok {
            log.Printf(&quot;received on ch1 : %s&quot;, rec)
        }
    case rec, ok := &lt;-ch2:
        if ok {
            log.Printf(&quot;received on ch2 : %s&quot;, rec)
        }
    default:
        log.Println(&quot;default case&quot;)
    }
    log.Println(&quot;end&quot;)
}</code></pre>
<ul>
<li><p>The <span v-highlightjs><code class="go" v-pre style="display: inline">default</code></span> case is chosen</p></li>
<li><p>Nothing has been sent on the two channels</p></li>
<li><p>The two cases of the select statement are blocking, the communication operations cannot proceed.</p></li>
</ul>
<div id="empty-select-statement" class="anchor"></div>
<h2 data-number="7.3"><span class="header-section-number">7.3</span> Empty select statement <a href="#empty-select-statement"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>An empty select statement blocks the goroutine indefinitively.</p>
<pre v-highlightjs><code class="go" v-pre >package main

func main() {
    select {}
}</code></pre>
<p>This program will cause a deadlock.</p>
<div id="some-common-use-cases-of-channels-and-selects" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Some common use cases of channels and selects <a href="#some-common-use-cases-of-channels-and-selects"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>I will detail in this section some common use cases of channel and selects that I find interesting.</p>
<div id="graceful-shutdown-of-an-http-server" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Graceful shutdown of an HTTP server <a href="#graceful-shutdown-of-an-http-server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A program has the ability to stop in a clean and ordened way if it implements a “graceful shutdown” mechanism.</p>
<p>This mechanism has to detect that the program has been interrupted. The <span v-highlightjs><code class="go" v-pre style="display: inline">os/signal</code></span> package has a dedicated function called <span v-highlightjs><code class="go" v-pre style="display: inline">Notify</code></span> that can detect OS signals such as an “interrupt”.</p>
<p>After detection of such a signal the program needs to implement a specific shutdown logic (save some state, delete some temporary objects, close some connections ...)</p>
<p>In this section we will take the example of an HTTP server :</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/server-graceful-shutdown/main.go
package main

import (
    &quot;context&quot;
    &quot;fmt&quot;
    &quot;log&quot;
    &quot;net/http&quot;
    &quot;os&quot;
    &quot;os/signal&quot;
    &quot;syscall&quot;
    &quot;time&quot;
)

func main() {
    // create the notification channel
    bye := make(chan os.Signal)
    signal.Notify(bye, os.Interrupt, syscall.SIGTERM)

    mux := http.NewServeMux()
    mux.Handle(&quot;/status&quot;, http.HandlerFunc(
        func(w http.ResponseWriter, r *http.Request) {
            fmt.Fprintf(w, &quot;OK&quot;)
        },
    ))
    srv := &amp;http.Server{
        Addr:    &quot;:8081&quot;,
        Handler: mux,
    }
    // launch the server in another goroutine
    go func() {
        // launch the server
        err := srv.ListenAndServe()
        if err != nil &amp;&amp; err != http.ErrServerClosed {
            log.Fatalf(&quot;server: %q\n&quot;, err)
        }
    }()
    // wait for os signal
    sig := &lt;-bye
    // the code below is executed when we receive an os.Signal
    log.Printf(&quot;detected os signal %s \n&quot;, sig)
    ctx, cancel := context.WithTimeout(context.Background(), 3*time.Second)
    err := srv.Shutdown(ctx)
    cancel()
    if err != nil {
        log.Fatal(err)
    }
}</code></pre>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">signal.Notify</code></span> will use the channel <span v-highlightjs><code class="go" v-pre style="display: inline">bye</code></span> to notify to our program when the program receive :</p></li>
<li><p>an interruption signal <span v-highlightjs><code class="go" v-pre style="display: inline">os.Interrupt</code></span></p>
<ul>
<li>a SIGTERM signal <span v-highlightjs><code class="go" v-pre style="display: inline">os.SIGTERM</code></span></li>
</ul></li>
<li><p>A classical server with one route <span v-highlightjs><code class="go" v-pre style="display: inline">"/status"</code></span> is created.</p></li>
<li><p>The server is launched into a new goroutine</p></li>
<li><p>On the main goroutine we wait for a potential os signal with <span v-highlightjs><code class="go" v-pre style="display: inline">sig := &lt;-bye</code></span></p></li>
<li><p>Once the signal has been received we execute this code :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >log.Printf(&quot;detected os signal %s \n&quot;, sig)
err := srv.Shutdown(context.Background())
if err != nil {
    log.Fatal(err)
}</code></pre>
<ul>
<li><p>We call <span v-highlightjs><code class="go" v-pre style="display: inline">srv.Shutdown</code></span> which will gracefully shuts down the server.</p></li>
<li><p>It will “first close all open listeners, then close all idle connections, and then wait indefinitely for connections to return to idle and then shut down” <a href="#fn6" class="footnote-ref" id="fnref6" role="doc-noteref"><sup>6</sup></a></p></li>
</ul>
<div id="timeout" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> Timeout <a href="#timeout"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A timeout is an amount of time that is allowed before an event takes place. It can be interesting to implement a timeout when your program rely on external resources that might become unavailable. Without a timeout your program might wailt indefinitely for the resource.</p>
<p>With a select statement we can add a timeout case :</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/timeout/main.go
package main

import (
    &quot;log&quot;
    &quot;time&quot;
)

func main() {
    ch := make(chan int, 1)
    select {
    case rec, ok := &lt;-ch:
        if ok {
            log.Printf(&quot;received %d&quot;, rec)
        }
    case rec, ok := &lt;-time.After(time.Second * 3):
        if ok {
            log.Printf(&quot;operation timed out at %s&quot;, rec)
        }
    }
}</code></pre>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">time.After</code></span> returns a receive only channel of <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> elements</p></li>
<li><p>After 3 seconds the timeout occurs</p></li>
</ul>
<p>The program output is :</p>
<pre v-highlightjs><code class="go" v-pre >2021/02/16 17:44:12 operation timed out at 2021-02-16 17:44:12.316518 +0100 CET m=+3.00403174</code></pre>
<div id="wait-groups" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Wait groups <a href="#wait-groups"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A wait group is a synchronization tool provided by the standard library. It can be used to wait for a group of goroutines to finish their tasks.</p>
<div id="example-3" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Example <a href="#example-3"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// concurrency/wait-group-without/main.go
package main

import (
    &quot;fmt&quot;
    &quot;time&quot;
)

func main() {
    fmt.Printf(&quot;Program start \n&quot;)
    for i := 0; i &lt; 10; i++ {
        go concurrentTaks(i)
    }
    finishTask()
    fmt.Printf(&quot;Program end \n&quot;)

}

func finishTask() {
    fmt.Println(&quot;Executing finish task&quot;)
}

func concurrentTaks(taskNumber int) {
    fmt.Printf(&quot;BEGIN Execute task number %d \n&quot;, taskNumber)
    time.Sleep(100 * time.Millisecond)
    fmt.Printf(&quot;END Execute task number %d \n&quot;, taskNumber)
}</code></pre>
<ul>
<li><p>We will launch 10 goroutines that will call the function <span v-highlightjs><code class="go" v-pre style="display: inline">concurrentTaks(i)</code></span> where <strong>i</strong> is the index of the loop counter.</p></li>
<li><p>After launching those goroutines we will call a function <span v-highlightjs><code class="go" v-pre style="display: inline">finishTask</code></span></p></li>
</ul>
<p>The previous program will output :</p>
<pre v-highlightjs><code class="go" v-pre >Program start
Executing finish task
Program end</code></pre>
<p>It seems that we are not even launching our goroutines ! Why ? Thats because launching a goroutine does not block the main goroutine .…</p>
<p>We can modify our source code to add a wait group :</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/wait-group/main.go
package main

import (
    &quot;fmt&quot;
    &quot;sync&quot;
    &quot;time&quot;
)

func main() {
    fmt.Printf(&quot;Program start \n&quot;)
    // initialize the wait group
    var waitGroup sync.WaitGroup
    waitGroup.Add(10)
    for i := 0; i &lt; 10; i++ {
        go concurrentTaks(i, &amp;waitGroup)
    }
    waitGroup.Wait()
    finishTask()
    fmt.Printf(&quot;Program end \n&quot;)

}

func finishTask() {
    fmt.Println(&quot;Executing finish task&quot;)
}

func concurrentTaks(taskNumber int, waitGroup *sync.WaitGroup) {
    fmt.Printf(&quot;BEGIN Execute task number %d \n&quot;, taskNumber)
    time.Sleep(100 * time.Millisecond)
    fmt.Printf(&quot;END Execute task number %d \n&quot;, taskNumber)
    waitGroup.Done()
}</code></pre>
<p>This code snippet will output the following :</p>
<pre v-highlightjs><code class="go" v-pre >Program start
BEGIN Execute task number 3
BEGIN Execute task number 9
BEGIN Execute task number 7
...
END Execute task number 9
END Execute task number 7
END Execute task number 3
Executing finish task
Program end</code></pre>
<p>We have the expected result. Our goroutines have executed. Let’s see what have changed.…</p>
<ul>
<li><p>At the beginning of the program we have created a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">sync.WaitGroup</code></span></p></li>
<li><p>We indicate to the wait group that he needs to wait for 10 units of work to be done.</p></li>
<li><p>The internal counter of the wait group will be incremented.</p></li>
<li><p>Then we pass the a pointer to the wait group to our goroutines.</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >go concurrentTaks(i, &amp;waitGroup)</code></pre>
<p>The function concurrentTaks is also modified (it’s signature, but also its body) :</p>
<pre v-highlightjs><code class="go" v-pre >func concurrentTaks(taskNumber int, waitGroup *sync.WaitGroup) {
    fmt.Printf(&quot;BEGIN Execute task number %d \n&quot;, taskNumber)
    time.Sleep(100 * time.Millisecond)
    fmt.Printf(&quot;END Execute task number %d \n&quot;, taskNumber)
    waitGroup.Done()
}</code></pre>
<ul>
<li><p>We are calling the method <span v-highlightjs><code class="go" v-pre style="display: inline">waitGroup.Done()</code></span> at the end of the function.</p></li>
<li><p>The <span v-highlightjs><code class="go" v-pre style="display: inline">Done</code></span> method will decrement the internal counter.</p></li>
</ul>
<p>When the internal counter has reached 0 (meaning that all our goroutines have finished) the main goroutine is released.<a href="#fn7" class="footnote-ref" id="fnref7" role="doc-noteref"><sup>7</sup></a>.</p>
<pre v-highlightjs><code class="go" v-pre >for i := 0; i &lt; 10; i++ {
    //...
}
waitGroup.Wait() // we block the main goroutine (to wait for the previous goroutines to finish)
finishTask()
fmt.Printf(&quot;Program end \n&quot;)</code></pre>
<div id="what-you-need-to-remember" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> What you need to remember <a href="#what-you-need-to-remember"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li>Create a waitGroup :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >var waitGroup sync.WaitGroup</code></pre>
<ul>
<li>Increment the wait group counter :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >waitGroup.Add(1)</code></pre>
<ul>
<li>Decrement the wait group counter :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >waitGroup.Done()</code></pre>
<ul>
<li>Block the current goroutine until all goroutines have finished :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >waitGroup.Wait()</code></pre>
<ul>
<li>You must pass a <strong>pointer to the wait group</strong> to your goroutines.</li>
</ul>
<div id="application-wait-group-channels" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Application : wait group &amp; channels <a href="#application-wait-group-channels"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="mission" class="anchor"></div>
<h2 data-number="10.1"><span class="header-section-number">10.1</span> Mission <a href="#mission"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>An e-commmerce website saves each visit in a database. For each visit we have :</p>
<ul>
<li><p>An unique id (UUID v4)</p>
<ul>
<li>ex : 5ce7fef2-c53a-4b79-bcbb-669c9799fa86</li>
</ul></li>
<li><p>The page visited (string)</p>
<ul>
<li>ex : /product-6</li>
</ul></li>
<li><p>The date of the visit</p></li>
<li><p>A session hash</p>
<ul>
<li><p>ex : 8722b6f78a69aeac3736bfcaa1dd7e4e7a77834dec2adf27e007ed1c998b34df</p></li>
<li><p>It is used to group page views by sessions</p></li>
</ul></li>
</ul>
<p>The marketing team wants to to list the most popular products and their related performance.</p>
<p>They want to have for each date the number of visits for each page :</p>
<ul>
<li><p>Date : 25 January 2020 :</p>
<ul>
<li><p>/product-45 : 254 visits</p></li>
<li><p>/product-22 : 2345 visits</p></li>
<li><p>/home : 2000 visits.</p></li>
<li><p>.…</p></li>
</ul></li>
<li><p>Date : 30 January 2020 :</p>
<ul>
<li><p>/product-45 : 125 visits</p></li>
<li><p>/home : 2000 visits.</p></li>
<li><p>...</p></li>
</ul></li>
</ul>
<p>After querying the database of visits you have extracted a JSON file with the following format :</p>
<pre v-highlightjs><code class="go" v-pre >{
  &quot;20-02-2019&quot;: [
    {
      &quot;id&quot;: &quot;b5c92427-7095-470e-985b-8df7ebe7ed2f&quot;,
      &quot;page&quot;: &quot;/product-8&quot;,
      &quot;sessionHash&quot;: &quot;48cd2ad413df8fd263d745275a6a9a95b9cad5374b67d6877c764fb40f29fd0c&quot;
    },
    {
      &quot;id&quot;: &quot;bdbd3948-b336-4cb1-badd-390dfe8c0459&quot;,
      &quot;page&quot;: &quot;/product-1&quot;,
      &quot;sessionHash&quot;: &quot;c02793f8fb7bb09efb7721aa5962c1b8de809e7ac720463f6ea66c0f4f7957b2&quot;
    },
    {
      &quot;id&quot;: &quot;b9a70a12-f6b1-4d7c-ae61-73fcd80b9992&quot;,
      &quot;page&quot;: &quot;/product-8&quot;,
      &quot;sessionHash&quot;: &quot;774f6b0f07c2ee4bce93ee36a9678b2f1022cda646ce36b1fc3b3205dee5646a&quot;
    }
  ],
  &quot;19-02-2019&quot;: [
    {
      &quot;id&quot;: &quot;9c2887c4-19ed-4cce-8998-3fe8b3662fba&quot;,
      &quot;page&quot;: &quot;/product-6&quot;,
      &quot;sessionHash&quot;: &quot;5d91fe5022b6186dc1fb81d8f31122fccf0a036f5f381d7dfc298b6aaf889e35&quot;
    },
    {
      &quot;id&quot;: &quot;adbf1651-f6bd-453a-97af-c860d153e981&quot;,
      &quot;page&quot;: &quot;/product-3&quot;,
      &quot;sessionHash&quot;: &quot;48cd2ad413df8fd263d745275a6a9a95b9cad5374b67d6877c764fb40f29fd0c&quot;
    },
    {
      &quot;id&quot;: &quot;80a09881-675f-4569-bdb0-28c6983aaee4&quot;,
      &quot;page&quot;: &quot;/basket&quot;,
      &quot;sessionHash&quot;: &quot;5d91fe5022b6186dc1fb81d8f31122fccf0a036f5f381d7dfc298b6aaf889e35&quot;
    }
  ]
}</code></pre>
<p>You are asked to build a program with the following input/output :</p>
<ul>
<li><p>INPUT : A JSON file similar to the previous snippet.</p>
<ul>
<li>You can download a sample JSON file here :</li>
</ul></li>
<li><p>OUTPUT : the statistics for each day formatted in JSON.</p></li>
</ul>
<p>Example OUTPUT :</p>
<pre v-highlightjs><code class="go" v-pre >[
  {
    &quot;date&quot;: &quot;20-02-2019&quot;,
    &quot;byPage&quot;: {
      &quot;/product-8&quot;: 2,
      &quot;/product-1&quot;: 1
    }
  },
  {
    &quot;date&quot;: &quot;19-02-2019&quot;,
    &quot;byPage&quot;: {
      &quot;/basket&quot;: 1,
      &quot;/product-3&quot;: 1,
      &quot;/product-6&quot;: 1
    }
  }
]</code></pre>
<p>Your program should use channels and wait groups.</p>
<div id="recommendations" class="anchor"></div>
<h3 data-number="10.1.1"><span class="header-section-number">10.1.1</span> Recommendations <a href="#recommendations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li><p>You will need to load the contents of the INPUT file into memory</p></li>
<li><p>You will also need to unmarshal the JSON data loaded</p></li>
<li><p>Think about how you can split the work into multiple independant tasks.</p></li>
<li><p>Create a worker function that will handle a single tasks</p></li>
<li><p>Your workers can communicate with your main goroutine through channels</p></li>
</ul>
<div id="solution" class="anchor"></div>
<h2 data-number="10.2"><span class="header-section-number">10.2</span> Solution <a href="#solution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="project-initialization" class="anchor"></div>
<h3 data-number="10.2.1"><span class="header-section-number">10.2.1</span> Project initialization <a href="#project-initialization"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li><p>Create a directory</p></li>
<li><p>And then launch the command go mod init name/of/your/module to initialize the module</p></li>
</ul>
<div id="load-input-and-unmarshal-json" class="anchor"></div>
<h3 data-number="10.2.2"><span class="header-section-number">10.2.2</span> Load input and unmarshal JSON <a href="#load-input-and-unmarshal-json"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>First we create a struct that will allow us to unmarshall the given input :</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/application1/visit/visit.go
package visit

type Visit struct {
    ID          string `json:&quot;id&quot;`
    Page        string `json:&quot;page&quot;`
    SessionHash string `json:&quot;sessionHash&quot;`
}</code></pre>
<p>This struct type is placed into a new package visit.</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/application1/main.go

data, err := ioutil.ReadFile(&quot;data.json&quot;)
if err != nil {
    log.Fatal(err)
}
dayStats := make(map[string][]visit.Visit)
err = json.Unmarshal(data, &amp;dayStats)
if err != nil {
    log.Fatal(err)
}</code></pre>
<ul>
<li><p>The file data.json is loaded</p></li>
<li><p>Next we initialize a map <span v-highlightjs><code class="go" v-pre style="display: inline">dayStats</code></span> that will contain the unmarshaled data</p>
<ul>
<li>For each date we have a slice of <span v-highlightjs><code class="go" v-pre style="display: inline">visit.Visit</code></span>.</li>
</ul></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshal</code></span> parses the input json and stores the result into <span v-highlightjs><code class="go" v-pre style="display: inline">dayStats</code></span>.</p>
<div id="how-to-split-the-work" class="anchor"></div>
<h3 data-number="10.2.3"><span class="header-section-number">10.2.3</span> How to split the work ? <a href="#how-to-split-the-work"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>We can split the work by date. Each daily load of visits can be treated independently. We create a <span v-highlightjs><code class="go" v-pre style="display: inline">Task</code></span> struct :</p>
<pre v-highlightjs><code class="go" v-pre >type Task struct {
    Date   string
    Visits []visit.Visit
}</code></pre>
<p>We will give to each worker a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">Task</code></span> through an input channel.</p>
<div id="output-struct" class="anchor"></div>
<h3 data-number="10.2.4"><span class="header-section-number">10.2.4</span> Output struct <a href="#output-struct"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The output of our progam is a slice of daily statistics. We create a type struct <span v-highlightjs><code class="go" v-pre style="display: inline">DailyStat</code></span> to hold the stats for a particular day :</p>
<pre v-highlightjs><code class="go" v-pre >type DailyStat struct {
    Date   string         `json:&quot;date&quot;`
    ByPage map[string]int `json:&quot;byPage&quot;`
}</code></pre>
<p>The final output has the type <span v-highlightjs><code class="go" v-pre style="display: inline">[]DailyStat</code></span> (a slice of the previous struct)</p>
<div id="wait-group-channel-creation" class="anchor"></div>
<h3 data-number="10.2.5"><span class="header-section-number">10.2.5</span> Wait group / channel creation <a href="#wait-group-channel-creation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >var w8 sync.WaitGroup
w8.Add(len(dayStats))</code></pre>
<ul>
<li><p>We initialize a wait group: <span v-highlightjs><code class="go" v-pre style="display: inline">w8</code></span></p></li>
<li><p>Right after initialization we add to it’s internal counter the value <span v-highlightjs><code class="go" v-pre style="display: inline">numberOfWorkers</code></span></p></li>
<li><p>By doing so we prepare our program to wait for each worker to finish</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >inputCh := make(chan Task, 10)
outputCh := make(chan DailyStat, len(dayStats))</code></pre>
<ul>
<li>We create two bidirectional buffered channels.</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">inputCh</code></span> will be used to send the tasks to the workers.</p>
<ul>
<li><p>This channel has a buffer of 10</p>
<ul>
<li><p>By setting the buffer size to 10 we will control the “throughput” (the rate at which our tasks will be processed)</p></li>
<li><p>The main goroutine can send 10 task maximum into the channel</p></li>
<li><p>When 10 tasks are sent, the main goroutine will block until it can add a new task</p></li>
</ul></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">outputCh</code></span> will be used to communicate the results from workers to the main goroutine</p>
<figure>
<b-img :src="require('@/assets/images/application_channel_chans.png')" alt="Input / output channels"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Input / output channels</figcaption>
</figure>
<div id="worker" class="anchor"></div>
<h3 data-number="10.2.6"><span class="header-section-number">10.2.6</span> Worker <a href="#worker"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// concurrency/application1/main.go 

func worker(in chan Task, workerId int, out chan DailyStat, w8 *sync.WaitGroup) {
    for received := range in {
        m := make(map[string]int)
        for _, v := range received.Visits {
            m[v.Page]++
        }
        out &lt;- DailyStat{
            Date:   received.Date,
            ByPage: m,
        }
        fmt.Printf(&quot;[worker %d] finished task \n&quot;, workerId)
    }
    // when the channel is closed the for loop is exited
    log.Println(&quot;worker quit&quot;)
    w8.Done()
}</code></pre>
<p>The worker is a function. It takes as input an input channel and an output channel.</p>
<ul>
<li><p>With a for loop we range over message received from the input channel.s</p></li>
<li><p>Each message is a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">Task</code></span></p></li>
<li><p>With a map we will count the visits for each page.</p>
<ul>
<li><p><u>Key</u> : the page name (ex : /product-8)</p></li>
<li><p><u>Value</u>: the number of visits (ex : 458)</p></li>
</ul></li>
<li><p>Once the visits are counted we send the result to the output channel.</p></li>
<li><p>The for loop will exit when the close signal is sent to the input channel</p></li>
<li><p>At the end the wait group counter is decreased by one : <span v-highlightjs><code class="go" v-pre style="display: inline">w8.Done()</code></span></p></li>
<li><p>We signal to the main goroutine that one goroutine has finished it’s mission.</p></li>
</ul>
<div id="worker-creation-and-send-tasks-to-the-input-channel" class="anchor"></div>
<h3 data-number="10.2.7"><span class="header-section-number">10.2.7</span> Worker creation and send tasks to the input channel <a href="#worker-creation-and-send-tasks-to-the-input-channel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// create the workers
for k := 0; k &lt; numberOfWorkers; k++ {
    go worker(inputCh, k, outputCh, &amp;w8)
}
// send the tasks
for date, visits := range dayStats {
    inputCh &lt;- Task{
        Date:   date,
        Visits: visits,
    }
}
// we say that we will not send any new data on the input channel
close(inputCh)
// wait for all tasks to be completed
w8.Wait()</code></pre>
<ul>
<li><p>We create our workers with a for loop</p></li>
<li><p>We iterate over <span v-highlightjs><code class="go" v-pre style="display: inline">dayStats</code></span> to send the tasks to the input channel</p></li>
<li><p>We close the input channel when all tasks are sent.</p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">w8.Wait()</code></span> will wait for all workers to finish</p>
<div id="collect-the-results" class="anchor"></div>
<h3 data-number="10.2.8"><span class="header-section-number">10.2.8</span> Collect the results <a href="#collect-the-results"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// when all treatment is finished we close the output channel
close(outputCh)
// collect the result
done := make([]DailyStat, 0, len(dayStats))
for out := range outputCh {
    done = append(done, out)
}

res, err := json.Marshal(done)
if err != nil {
    log.Fatal(err)
}
err = ioutil.WriteFile(&quot;results.json&quot;, res, 0644)
if err != nil {
    log.Fatal(err)
}
log.Println(&quot;done !&quot;)</code></pre>
<ul>
<li><p>The last part is simple</p></li>
<li><p>We close the output channel</p></li>
<li><p>Then we collect the results from the output channel (with a for loop)</p></li>
<li><p>Each result is appended to the <span v-highlightjs><code class="go" v-pre style="display: inline">done</code></span> slice</p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">done</code></span> is then marshalled to JSON and the result is persisted to the result.json file.</p>
<div id="mutexes" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Mutexes <a href="#mutexes"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="a-synchronization-tool" class="anchor"></div>
<h2 data-number="11.1"><span class="header-section-number">11.1</span> A synchronization tool <a href="#a-synchronization-tool"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A mutex is a synchronization tool. Mutex is the abbreviation of <strong>Mut</strong>ual <strong>Ex</strong>clusion. Let’s make a detour via logic theory to better understand this concept.</p>
<p>We say that two events are <strong>mutually exclusive</strong> if they cannot occur at the same time. For example the two events “the bike turn on the left” and “the bike turn on the right” are mutually exclusive. The biker cannot turn left and right at the same time.</p>
<p>This mutual exclusion property is interesting for us. When we build concurrent programs a data race may occur.</p>
<p>A data race may occur if two (or more) threads of execution access a shared variable</p>
<ul>
<li><p>AND when one (or more) thread(s) want to update the variable</p></li>
<li><p>AND there is no “explicit mechanism to prevent the accesses from being simultaneous” in the threads <b-link class="citation" data-cites="savage1997eraser" href="#savage1997eraser" >[@savage1997eraser]</b-link>.</p></li>
</ul>
<p>We can avoid a data race by using a mutex. I’ll show you how :</p>
<div id="example-usage" class="anchor"></div>
<h2 data-number="11.2"><span class="header-section-number">11.2</span> Example usage <a href="#example-usage"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="buggy-program" class="anchor"></div>
<h3 data-number="11.2.1"><span class="header-section-number">11.2.1</span> Buggy program <a href="#buggy-program"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// concurrency/mutex-bug/main.go
package main

import (
    &quot;fmt&quot;
    &quot;log&quot;
    &quot;net/http&quot;
)

var requestCount int

func main() {
    http.HandleFunc(&quot;/status&quot;, status)
    err := http.ListenAndServe(&quot;:8090&quot;, nil)
    if err != nil {
        log.Fatal(err)
    }
}

func status(w http.ResponseWriter, req *http.Request) {
    requestCount++
    fmt.Fprintf(w, &quot;OK - count : %d \n&quot;, requestCount)
}</code></pre>
<ul>
<li><p>We created here a simple HTTP web server.</p></li>
<li><p>It has one single route : <span v-highlightjs><code class="go" v-pre style="display: inline">"/status"</code></span></p></li>
<li><p>We created a global variable <span v-highlightjs><code class="go" v-pre style="display: inline">requestCount</code></span> of type int</p></li>
<li><p>This variable is incremented each time a request is sent to the route <span v-highlightjs><code class="go" v-pre style="display: inline">"/status"</code></span></p></li>
</ul>
<p>Let’s create another program that will call this server...</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/mutex-server-call/main.go
package main

import (
    &quot;io/ioutil&quot;
    &quot;log&quot;
    &quot;net/http&quot;
    &quot;sync&quot;
)

func main() {
    var w8 sync.WaitGroup
    w8.Add(10)
    for k := 0; k &lt; 10; k++ {
        go caller(&amp;w8)
    }
    w8.Wait()
}

func caller(w8 *sync.WaitGroup) {
    for k := 0; k &lt; 100; k++ {
        res, err := http.Get(&quot;http://localhost:8090/status&quot;)
        if err != nil {
            log.Fatal(err)
        }
        defer res.Body.Close()
        s, err := ioutil.ReadAll(res.Body)
        if err != nil {
            log.Fatal(err)
        }
        log.Println(string(s))
    }
    w8.Done()
}</code></pre>
<p>This program will launch <strong>10 goroutines</strong> that will each send <strong>100 requests</strong> to the server <strong>concurrently</strong> (10 * 100 = 1,000 requests). Note that we used a wait group to wait for all goroutines to finish.</p>
<p>This program will print the body received at each request. Here are the 3 last lines</p>
<pre v-highlightjs><code class="go" v-pre >//...
2021/02/17 18:43:49 OK - count : 989 
2021/02/17 18:43:49 OK - count : 990 
2021/02/17 18:43:49 OK - count : 991</code></pre>
<p>Wow ! We made 1,000 request but our counter displays 991 ...</p>
<div id="add-a-mutex" class="anchor"></div>
<h3 data-number="11.2.2"><span class="header-section-number">11.2.2</span> Add a mutex <a href="#add-a-mutex"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Let’s add a mutex to our previous code</p>
<pre v-highlightjs><code class="go" v-pre >// concurrency/mutex-added/main.go
package main

import (
    &quot;fmt&quot;
    &quot;log&quot;
    &quot;net/http&quot;
    &quot;sync&quot;
)

var mu sync.Mutex
var requestCount int

func main() {
    http.HandleFunc(&quot;/status&quot;, status)
    err := http.ListenAndServe(&quot;:8091&quot;, nil)
    if err != nil {
        log.Fatal(err)
    }
}

func status(w http.ResponseWriter, req *http.Request) {
    mu.Lock()
    requestCount++
    mu.Unlock()
    fmt.Fprintf(w, &quot;OK - count : %d \n&quot;, requestCount)
}</code></pre>
<ul>
<li><p>I created a new global variable named <span v-highlightjs><code class="go" v-pre style="display: inline">mu</code></span> of type <span v-highlightjs><code class="go" v-pre style="display: inline">sync.Mutex</code></span>.</p></li>
<li><p>In the <span v-highlightjs><code class="go" v-pre style="display: inline">status</code></span> function we added 2 new lines before and after the incrementation</p></li>
<li><p>Before incrementing we call <span v-highlightjs><code class="go" v-pre style="display: inline">mu.Lock()</code></span></p></li>
<li><p>After we call <span v-highlightjs><code class="go" v-pre style="display: inline">mu.Unlock()</code></span></p></li>
</ul>
<p>If we test the program the counter will work as expected this time. <span v-highlightjs><code class="go" v-pre style="display: inline">sync.Mutex</code></span> API The mutex API is simple. It has 2 exported methods : <span v-highlightjs><code class="go" v-pre style="display: inline">Lock()</code></span> : this method will attempt to acquire the lock, If the lock is already in use by another goroutine it will block until it’s free</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Unlock()</code></span> : this method will release the lock. It will generate a runtime error if the mutex has not been locked before</p>
<div id="mutex-hat" class="anchor"></div>
<h2 data-number="11.3"><span class="header-section-number">11.3</span> Mutex hat <a href="#mutex-hat"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>There is a pretty common idiom called the Mutex hat<a href="#fn8" class="footnote-ref" id="fnref8" role="doc-noteref"><sup>8</sup></a>. In our previous example, we created a global mutex. Doing so is not advised. Mutexes are usually used in struct types.</p>
<p>It is common to see a field of type sync.Mutex in a struct. Generally it is placed on top of the shared ressource it has to protect.</p>
<p>Here is an example from the standard library (file database/sql/sql.go , package sql)</p>
<pre v-highlightjs><code class="go" v-pre >type DB struct {
    //...

    mu           sync.Mutex // protects following fields
    freeConn     []*driverConn
    connRequests map[uint64]chan connRequest
    nextRequest  uint64 // Next key to use in connRequests.
    numOpen      int    // number of opened and pending open connections

    //...
}</code></pre>
<p>Here the mutex mu protects 4 fields. It is placed on top of those fields (like an hat)</p>
<div id="concurrent-program-design" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Concurrent program design <a href="#concurrent-program-design"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Designing concurrent programs is not easy. It requires some experience, but also some methodology. In this section we detail some tips that you can use to compose concurrent programs.</p>
<p>We will use some concepts and ideas developped by T. Mattson in it’s excellent book <b-link class="citation" data-cites="mattson2004patterns" href="#mattson2004patterns" >[@mattson2004patterns]</b-link>.</p>
<div id="identifying-the-tasks" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Identifying the tasks <a href="#identifying-the-tasks"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A <strong>task</strong> is an operation (that can be composed of sub-operations). A task can be composed of :</p>
<ul>
<li><p>Input/Output operations</p>
<ul>
<li><p>Reading a file</p></li>
<li><p>Opening a tcp connection</p></li>
</ul></li>
<li><p>A “computational” operation</p>
<ul>
<li><p>Get the remainder of an integer division</p></li>
<li><p>Iterate over a slice to find a specific element index.</p></li>
</ul></li>
</ul>
<p>A task can be a single function, a method or a group of functions and methods.</p>
<div id="input-and-output" class="anchor"></div>
<h4 data-number="12.1.0.1"><span class="header-section-number">12.1.0.1</span> Input and output <a href="#input-and-output"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>When considering a task it can be helpfull to isolate clearly :</p>
<ul>
<li><p>The <strong>input</strong> : the data used in the task</p>
<ul>
<li><p>Ex :</p>
<ul>
<li><p>A slice of integers</p></li>
<li><p>A map</p></li>
<li><p>A pointer to a variable</p></li>
</ul></li>
</ul></li>
<li><p>The <strong>output</strong> : this is what is returned by the task.</p>
<ul>
<li><p>Ex :</p>
<ul>
<li><p>A modified version of the input data.</p></li>
<li><p>The result of a computation</p></li>
<li><p>A date, a string, a duration</p></li>
</ul></li>
</ul></li>
</ul>
<div id="identify-the-dependencies" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> Identify the dependencies <a href="#identify-the-dependencies"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A dependency is a link between two or more tasks. We can characterize two types of dependencies :</p>
<ul>
<li><p><strong>Order dependency</strong> : the taks needs to be performed before or after another one.</p></li>
<li><p><strong>Data dependency</strong> : the execution of two tasks depend on the same piece of data</p></li>
</ul>
<div id="example-of-order-dependency" class="anchor"></div>
<h3 data-number="12.2.1"><span class="header-section-number">12.2.1</span> Example of order dependency <a href="#example-of-order-dependency"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Usually the order dependency is quite easy to understand and detect. For instance let’s take the example of an ecommerce website. We have isolated the following two tasks :</p>
<ul>
<li><p>Processing the payment of the order</p></li>
<li><p>Shipping the parcel</p></li>
</ul>
<p>It’s obvious that we need to process the payment before sending the goods. There is an order dependency between those two tasks. One task needs to be done before the other one.</p>
<div id="example-of-data-dependency" class="anchor"></div>
<h3 data-number="12.2.2"><span class="header-section-number">12.2.2</span> Example of data dependency <a href="#example-of-data-dependency"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Let’s keep the example of the ecommerce software. Usually this kind of applications store the product details and the stocks for each product. You have the three following tasks :</p>
<ul>
<li><p>Decreasing the stock when an order has been placed</p></li>
<li><p>Reading the stock to display on the front end of the site</p></li>
</ul>
<p>Those two tasks depend on the stock data. In the first task (decreading the stock) we update it. In the second one we just read it. Those two operations are dependant on the stock variable.</p>
<p>What happens if you read the stock and you update it at the exact same time ? You might display to the client wrong stock information. Data dependency cause data races if you do not implement mechanisms to prevent them.</p>
<div id="build" class="anchor"></div>
<h2 data-number="12.3"><span class="header-section-number">12.3</span> Build <a href="#build"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The first step is to identify :</p>
<ul>
<li><p>Tasks</p></li>
<li><p>Tasks input / output</p></li>
<li><p>Dependencies between tasks</p></li>
</ul>
<p>The next step is to use those informations to build your program.</p>
<ol type="1">
<li><p>If you have no dependencies between your tasks you are in a dream situation. All your tasks can be executed concurrently.</p></li>
<li><p>If you notice an order dependency then you can build a chain of workers.</p>
<ol type="1">
<li><p>Let’s say you have 3 groups of tasks</p></li>
<li><p>We can create 3 workers</p></li>
<li><p>The workers will execute concurrently but you have to make sure that tasks will be executed in the given order</p></li>
<li><p>It can be achieved with 3 channels linking the 3 workers</p></li>
</ol></li>
<li><p>When you spot a data dependency your program can be subject to data races.</p>
<ol type="1">
<li>Adding a mutex can usually protect you against this trap.</li>
</ol></li>
</ol>
<p>Of course those 3 situations may appear at the same time. As a consequence writing a concurrent program might be difficult. However mapping tasks and dependencies certainly help in the design process.</p>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="13.1"><span class="header-section-number">13.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// concurrency/test-question-1/main.go
package main

import &quot;fmt&quot;

func main() {
    go count()
}

func count() {
    for k := 0; k &lt; 10; k++ {
        fmt.Println(k)
    }
}</code></pre>
<ol type="1">
<li><p>What is the output of program 1?</p></li>
<li><p>True or False ? In a Go program you have at least one goroutine.</p></li>
<li><p>How to initialize an unbuffered channel of <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span> ?</p></li>
<li><p>In which case(s) a send operation is blocking ?</p></li>
<li><p>In a select statement, in which case(s) the default case is executed ?</p></li>
<li><p>True or False ? You should always pass a copy of a wait group to a goroutine, otherwise a data race may occur.</p></li>
<li><p>Fill in the blank. The method ____ from <span v-highlightjs><code class="go" v-pre style="display: inline">sync.WaitGroup</code></span> decrements it’s internal counter.</p></li>
<li><p>Describe (with your own words) what a Mutex is.</p></li>
<li><p>Can you detect data races when you build your programs ?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="13.2"><span class="header-section-number">13.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the output of the program 1?</p>
<ol type="1">
<li><p>Nothing</p></li>
<li><p>In this program we launch a goroutine.</p></li>
<li><p>The main goroutine will <strong>NOT WAIT</strong> for the secong goroutine to return.</p></li>
<li><p>You can use a wait group for instance to wait for the end of the second goroutine</p></li>
</ol></li>
<li><p>True or False. In a Go program you have at least one goroutine.</p>
<ol type="1">
<li><p>True</p></li>
<li><p>It is called the “main goroutine”</p></li>
</ol></li>
<li><p>How to initialize an unbuffered channel of <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span> ?</p>
<ol type="1">
<li><p>with the <span v-highlightjs><code class="go" v-pre style="display: inline">make</code></span> builtin</p></li>
<li><p>make(chan uint8)</p></li>
</ol></li>
<li><p>In which case(s) a send operation is blocking ?</p>
<ol type="1">
<li><p>For unbuffered channels</p>
<ol type="1">
<li>A send operation is blocking until a receiver is ready to receive the message sent</li>
</ol></li>
<li><p>For buffered channels</p>
<ol type="1">
<li>A send operation blocks when the channel buffer is full</li>
</ol></li>
</ol></li>
<li><p>In a select statement, in which case(s) the default case is executed ?</p>
<ol type="1">
<li><p>A select statement list communication operations</p></li>
<li><p>When no communication operation can proceed the default case is executed.</p></li>
</ol></li>
<li><p>True or False ? You should always pass a copy of a wait group to a goroutine, otherwise a data race may occur.</p>
<ol type="1">
<li><p>False</p></li>
<li><p>You should always pass a pointer to a wait group to your goroutines</p></li>
</ol></li>
<li><p>Fill in the blank. The method ____ from <span v-highlightjs><code class="go" v-pre style="display: inline">sync.WaitGroup</code></span> decrements it’s internal counter.</p>
<ol type="1">
<li><p>Done()</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Add(-1)</code></span> is also valid.</p></li>
</ol></li>
<li><p>Describe (with your own words) what a Mutex is.</p>
<ol type="1">
<li><p>A Mutex is a synchronization primitive that is used to synchronize the usage of a shared resource.</p></li>
<li><p>It is a protection against data races.</p></li>
</ol></li>
<li><p>Can you detect data races when you build your programs ?</p>
<ol type="1">
<li><p>Yes !</p></li>
<li><p>Go can detect them.</p></li>
<li><p>Use the <span v-highlightjs><code class="go" v-pre style="display: inline">-race</code></span> flag when you build your program.</p></li>
</ol></li>
</ol>
<div id="key-takeways" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Key Takeways <a href="#key-takeways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>A goroutine can be created with the <span v-highlightjs><code class="go" v-pre style="display: inline">go</code></span> statement</p></li>
<li><p>Any function can be executed in a goroutine</p>
<ul>
<li>Ex : <span v-highlightjs><code class="go" v-pre style="display: inline">go myFunc(a,b)</code></span></li>
</ul></li>
<li><p>A data race may occur if two (or more) threads of execution access a shared variable</p>
<ul>
<li><p>AND when one (or more) thread(s) want to update the variable</p></li>
<li><p>AND there is no “explicit mechanism to prevent the accesses from being simultaneous” in the threads <b-link class="citation" data-cites="savage1997eraser" href="#savage1997eraser" >[@savage1997eraser]</b-link></p></li>
<li><p>To detect data race you can use the -race flag when building or testing your programs</p></li>
<li><p>To protect a shared resource you can use a <strong>mutex</strong>.</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">var mu sync.Mutex</code></span></p></li>
</ul></li>
<li><p>A deadlock occurs when two components of a program are waiting for each others</p></li>
<li><p>A channel is typed communication pipeline</p></li>
<li><p>Goroutines can communicate together thanks to channels</p></li>
<li><p>Channels are allocated with the make builtin</p>
<ul>
<li><p>Bidirectional</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ch := make(chan int)</code></span></p></li>
<li><p>Send only</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ch := make(chan-&gt; int)</code></span></p></li>
<li><p>Receive only</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ch := make(chan&lt;- int)</code></span></p></li>
</ul></li>
<li><p>A channel can be buffered or unbuffered. The buffer size is set when the channel is created</p>
<ul>
<li><p>Unbuffered :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ch := make(chan int)</code></span></p></li>
<li><p>Buffered :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ch := make(chan int,8)</code></span></p>
<ul>
<li>Buffer size / capacity = 8</li>
</ul></li>
</ul></li>
<li><p>The arrow operator is used to send/receive data to/from a channel</p>
<ul>
<li><p>Send :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">syncCh &lt;- true</code></span></p></li>
<li><p>Receive</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">received := &lt;-syncCh</code></span></p></li>
</ul></li>
<li><p>Closing a channel = no more values will be sent on the channel</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">close(ch)</code></span></p></li>
<li><p>The builtin <span v-highlightjs><code class="go" v-pre style="display: inline">len</code></span> returns the number of elements queued in a channel.</p></li>
<li><p>Select statements are used to choose “which of a set of possible send or receive operations will proceed”<a href="#fn9" class="footnote-ref" id="fnref9" role="doc-noteref"><sup>9</sup></a>.</p>
<pre v-highlightjs><code class="go" v-pre >select {
case rec, ok := &lt;-ch1:
    if ok {
        log.Printf(&quot;received on ch1 : %s&quot;, rec)
    }
case rec, ok := &lt;-ch2:
    if ok {
        log.Printf(&quot;received on ch2 : %s&quot;, rec)
    }
default:
    log.Println(&quot;default case&quot;)
}</code></pre>
<ul>
<li><p>The first non blocking operation will be executed.</p></li>
<li><p>When all operations are blocking the default case is executed (if present)</p></li>
</ul></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">sync.WaitGroup</code></span> is a handy type struct that can be used to wait for a group of goroutines to finish</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// in the main goroutine
var w8 sync.WaitGroup
w8.Add(numberOfWorkers)

// when a worker has finished
w8.Done()

// blocks until all goroutines are done
w8.Wait()</code></pre>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>Source : https://en.wikipedia.org/wiki/Concurrency_(computer_science)<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>https://vimeo.com/49718712<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p>https://golang.org/ref/spec#Close<a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn4" role="doc-endnote"><p>Taken from https://golang.org/doc/effective_go#channels<a href="#fnref4" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn5" role="doc-endnote"><p>Source : https://golang.org/ref/spec#Select_statements<a href="#fnref5" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn6" role="doc-endnote"><p>Source : Go Source code documentation<a href="#fnref6" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn7" role="doc-endnote"><p>https://golang.org/src/sync/waitgroup.go<a href="#fnref7" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn8" role="doc-endnote"><p>Identified by Dmitri Shuralyov : https://dmitri.shuralyov.com/idiomatic-go#mutex-hat<a href="#fnref8" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn9" role="doc-endnote"><p>Source : https://golang.org/ref/spec#Select_statements<a href="#fnref9" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap30Concurrency"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap29DataStorageFilesAndDatabases'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Data storage : files and databases</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap31Logging'}">
									<p><u><small>Next</small></u></p>
									<p><small>Logging</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Concurrency - Practical Go Lessons"
const description = "“Concurrency is the ability of different parts or units of a program, algorithm, or problem to be executed out-of-order or in partial order, without affecting the final outcome”1 Concurrency refers to an ability. Go supports concurrency. We can write a Go program that will run a set of tasks concurrently. How to build concurrent programs in Go"

export default {
  name: "Chap30Concurrency",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
